/*
For general styles, that transcend components.
*/

:root {
  --main-background-colour: #ffffff;
  --main-text-colour: #414141;
  --main-strong-colour: #000000;
  --secondary-background-colour: #2C2C2C;
  --secondary-text-colour: #c2c2c2;
  --secondary-strong-colour: #ffffff;
  --link-colour: #144be6;

  --header-height: 4rem;
  --mobile-padding: 20px;

  --border-radius: 10px;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: sans-serif;
  background-color: var(--main-background-colour);
  color: var(--main-text-colour);
}

h1 {
  text-align: center;
}

h1, h2, h3, h4, h5, h6, b, strong {
  color: var(--main-strong-colour);
}

a {
  color: var(--link-colour)
}

.dark {
  background-color: var(--secondary-background-colour);
  color: var(--secondary-text-colour);
}

.dark h1,
.dark h2,
.dark h3,
.dark h4,
.dark h5,
.dark h6,
.dark b,
.dark strong {
  color: var(--secondary-strong-colour);
}

.content {
  width: 70%;
  max-width: 900px;
  margin: auto;
}

.button-row {
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.button-row > * {
  margin: 1rem;
}

@media screen and (max-width: 800px) {
  .content {
    width: 100%;
    padding: 0 var(--mobile-padding);
  }
}