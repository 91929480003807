.HelpUsingTheTool .contents {
    margin-bottom: 3em;
}

.HelpUsingTheTool .centred {
    text-align: center;
}

.HelpUsingTheTool img {
    margin: 2em;
    width: 40%;
    border: 1px solid black;
}

.HelpUsingTheTool .section {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.HelpUsingTheTool .section .icon {
    font-size: 5em;
    margin: 2%;
    margin-right: 5%;
}

.HelpUsingTheTool .section .tool-information {
    width: 80%;
}