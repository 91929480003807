.Button {
  border: none;
  font-size: 1.1rem;
  border-radius: 5px;
  cursor: pointer;
  padding: 0.5rem 1rem;
  background-color: var(--main-background-colour);
  color: var(--main-text-colour);
}

.Button.dark {
  background-color: var(--secondary-background-colour);
  color: var(--secondary-text-colour);
}

.Button:hover {
  color: var(--main-strong-colour);
}

.Button.dark:hover {
  color: var(--secondary-strong-colour);
}

.Button:disabled {
  color: grey;
  cursor: auto;
}