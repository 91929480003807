.Modal {
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  /* Don't cover the header. Not completely sure about this decision. */
  top: var(--header-height);
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Modal .actual {
  background-color: var(--main-background-colour);
  max-width: 900px;
  min-width: 50%;
  /* min-height: 25%; */
  border-radius: var(--border-radius);
  margin: 1rem;
}

.Modal .header, .Modal .body {
  padding: 1rem;
}

.Modal .header {
  font-size: 1rem;
  color: var(--secondary-strong-colour);
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
}
