.HelpCards {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr 1fr 1fr;
}

.HelpCards .Button {
  background-color: black;
}

.HelpCards .card {
  text-decoration: none;
  color: inherit;
  display: block;
  background-color: var(--secondary-background-colour);
  color: var(--secondary-text-colour);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  border-radius: var(--border-radius);
}

.HelpCards .card h2 {
  color: var(--secondary-strong-colour);
}

.HelpCards .card:hover {
  cursor: pointer;
}

.HelpCards .card:hover .Button {
  color: var(--secondary-strong-colour);
}

.HelpCards .card .Button {
  margin-top: auto;
}

@media screen and (max-width: 800px) {
  .HelpCards {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr;
  }
}