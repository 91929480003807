.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.App .main {
  flex-grow: 1;
}

.App footer {
  text-align: center;
  border-top: 1px solid black;
}