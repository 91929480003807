.FloorPlanDesigner {
  position: fixed;
  top: var(--header-height);
  height: calc(100vh - var(--header-height));
  width: 100vw;
}

.FloorPlanDesigner canvas {
  width: 100%;
  height: 100%;
}

.FloorPlanDesigner canvas.pan-selected {
  cursor: grab
}

.FloorPlanDesigner canvas.panning {
  cursor: grabbing
}