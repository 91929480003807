.HelpModifyingInUnityPage h1 {
    margin-top: 1.5em;
}

.HelpModifyingInUnityPage .centred {
    text-align: center;
}

.HelpModifyingInUnityPage .section {
    margin-top: 3em;
}

.HelpModifyingInUnityPage code {
    background: #eeeeee;
    border: 1px solid #d3d3d3;
    border-radius: 0.3em;
    line-height: 1.4;
    font-family: monospace;
    padding: 0.05em 0.05em;
}

.HelpModifyingInUnityPage li {
    line-height: 1.25;
    margin-bottom: 1em;
}

.HelpModifyingInUnityPage img {
    height: auto;
    max-width: 70%;
    display: block;
    margin: 0 auto;
    border: 1px solid black;
    margin-top: 2.5em;
    margin-bottom: 2.5em;
}