.ToolControls {
  position: fixed;
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: white;
  grid-gap: 2rem;
  padding: 1.5rem;
  border: 3px solid var(--secondary-background-colour);
  border-radius: var(--border-radius);
  top: calc(var(--header-height) + 1rem);
  left: 1rem;
  max-height: calc(100% - 2rem - var(--header-height));
  overflow-y: auto;
}

.ToolControls input[type="radio"] {
  display: none;
}

.ToolControls hr {
  grid-column: 1 / 3;
}

.ToolControls label, .ToolControls .control {
  cursor: pointer;
}

.ToolControls hr {
  width: 100%;
}