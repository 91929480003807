/* General styles. */

.Header .spacer {
  height: var(--header-height);
}

.Header header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  border-bottom: 1px solid black;
}

.Header a {
  text-decoration: none;
  color: var(--secondary-text-colour);
  width: 100%;
}

.Header a:hover {
  color: var(--secondary-strong-colour);
  background-color: black;
  cursor: pointer;
}

/* Header navigation styles. */

.Header .header-navigation {
  height: var(--header-height);
}

.Header .header-navigation > ul {
  height: 100%;
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: stretch;
}

.Header .header-navigation .item-container {
  display: flex;
  align-items: center;
  height: 100%;
}

.Header .header-navigation .logo {
  margin-right: 1rem;
  height: 2rem;
}

.Header .header-navigation a {
  padding: 0 1rem;
}

.Header .header-navigation .menu-button-item {
  margin-right: 0;
  margin-left: auto;
  color: var(--secondary-text-colour);
}

.Header .header-navigation .menu-button-item .item-container:hover {
  color: var(--secondary-strong-colour);
}

@media screen and (max-width: 800px) {
  .Header .header-navigation .menu-item {
    display: none;
  }
}

/* Menu navigation styles */

.Header .menu-container {
  height: 100%;
  position: relative;
}

.Header .menu-navigation {
  width: max-content;
  background-color: var(--secondary-background-colour);
  border: 1px solid black;
  border-top: none;
  position: absolute;
  right: calc(-1 *var(--mobile-padding));
}

.Header .menu-navigation ul {
  margin: 0;
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.Header .menu-navigation li {
  padding: 0;
}

.Header .menu-navigation a {
  display: block;
  padding: 1rem 1rem;
}

.Header .menu-navigation ul ul a {
  padding-left: 2rem;
}