.HomePage {
  margin-bottom: 2rem;
}

.HomePage .hero {
  overflow: auto;
  text-align: center;
  font-size: 1.6rem;
  height: calc(100vh - var(--header-height));
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.HomePage .arrow {
  margin: auto;
  width: 50px;
  height: 50px;
  transform: rotate(45deg);
  border-bottom: 5px solid white;
  border-right: 5px solid white;
  cursor: pointer;
}

.HomePage .scroll-padding {
  height: var(--header-height);
}

.HomePage .buttons *:first-child {
  margin-right: 1rem;
}

.HomePage .buttons .Button.dark {
  background-color: black;
}